import { graphql } from "gatsby"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { connect } from "react-redux"
import ShowMoreText from "react-show-more-text"
import SlideToggle from "react-slide-toggle"
import { Swiper, SwiperSlide } from "swiper/react"
import {
  getRecentPages,
  setRecentPages,
} from "../../../services/account-services"
import { isLoggedIn } from "../../../services/auth"
import { getBoardFromLocalStorage } from "../../../state/board"
import Layout from "../../../templates/Page"
import CreateBoardModal from "../../ContentBuilder/Modal/CreateBoardModal"
import ProductThumbnailsData from "../../ContentBuilder/SwiperList/ProductThumbnailsData"
import RecentViews from "../../RecentViews"
import FurnitureRelatedItems from "./FurnitureRelatedItems"

const FurnitureDetail = ({ data, currentBoards, dispatch }) => {
  const swiper_params = {
    spaceBetween: 10,
    navigation: {
      prevEl: ".swiper-button-prev-unique",
      nextEl: ".swiper-button-next-unique",
    },
    breakpoints: {
      1200: {
        slidesPerView: 4,
      },
      992: {
        slidesPerView: 3,
      },
      0: {
        slidesPerView: 2,
      },
    },
  }
  const [isReadmore, setReadmore] = useState(true)
  const [isViewmore, setViewmore] = useState(true)
  const [item, setItem] = useState({})
  const [variation, setVariation] = useState({})
  const [globalConfig, setGlobalConfig] = useState({})
  const [activeObjectID, setActiveObjectID] = useState(null)
  const [gallery, setGallery] = useState([])
  const [isSaved, setIsSaved] = useState(false)
  var _allVariations = []
  const variations = item.Variations
  const valid_status = ["Active"]

  var vid = null
  if (typeof window !== "undefined") {
    const search = typeof window !== "undefined" ? window.location.search : "/"
    const params = new URLSearchParams(search)
    vid = params.get("vid")
  }

  useEffect(() => {
    dispatch(getBoardFromLocalStorage())
  }, [])

  useEffect(() => {
    let found = false
    for (const board of currentBoards) {
      const items = JSON.parse(board.Items)
      for (const key in items) {
        if (
          !found &&
          key.includes("Furniture") &&
          items[key].ID == item.objectID &&
          items[key].VariantID == variation.objectID
        ) {
          setIsSaved(true)
          found = true
        }
      }
    }
    if (!found) setIsSaved(false)
    if (vid) {
      setActiveObjectID(vid)
    }
  }, [currentBoards, item, variation])

  useEffect(() => {
    if (data) {
      setGlobalConfig(data.silverStripeDataObject.UndigitalGlobalConfig)
      let _item = data.allSilverStripeDataObject.nodes[0].UndigitalFurniture
      setItem(_item)
      if (typeof _item.FeaturedVariation !== "undefined") {
        let _variation = _item.FeaturedVariation.UndigitalFurnitureVariation

        let found = false
        for (const board of currentBoards) {
          const items = JSON.parse(board.Items)
          for (const key in items) {
            if (
              !found &&
              key.includes("Furniture") &&
              items[key].ID == _item.objectID &&
              items[key].VariantID == _variation.objectID
            ) {
              setIsSaved(true)
              found = true
            }
          }
        }
        if (!found) setIsSaved(false)

        if (activeObjectID === null) {
          setActiveObjectID(_variation.objectID)
        }
        _variation.ColourText =
          _variation.Colour !== null
            ? _variation.Colour.UndigitalColour.name
            : ""
        _variation.StyleText =
          _variation.Style !== null
            ? _variation.Style.UndigitalProductStyle.name
            : ""
        let rrp = _variation.rrp
        rrp = parseFloat(Math.round(rrp * 1000) / 1000).toFixed(2)
        let formatted_rrp =
          "$" + rrp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        _variation.formattedPrice = formatted_rrp
        setVariation(_variation)
        let imagePosition = _variation.imagePosition
        let galleryItems = _variation.Gallery
        let _gallery = []
        if (galleryItems !== null) {
            var j = 0
            for (; galleryItems[j]; ) {
                let galleryItem = galleryItems[j].UndigitalGalleryImage
                galleryItem.imagePosition = imagePosition
                _gallery.push(galleryItem)
                j++
            }
            setGallery(_gallery)
        }
      }

      if (typeof variations !== "undefined" && variations) {
        var i = 0
        for (; variations[i]; ) {
          let _variation = variations[i].UndigitalFurnitureVariation
          let _variation_id = _variation.objectID
          if (_variation_id == vid) {
            setVariation(_variation)
          }
          let hash = null
          if (typeof window !== "undefined") {
            hash = window.location.hash.substring(1)
          }
          let isValid = valid_status.includes(_variation.status)
          if (isValid) {
            _variation.ColourText =
              _variation.Colour !== null
                ? _variation.Colour.UndigitalColour.name
                : ""
            _variation.StyleText =
              _variation.Style !== null
                ? _variation.Style.UndigitalProductStyle.name
                : ""
            let rrp = _variation.rrp
            rrp = parseFloat(Math.round(rrp * 1000) / 1000).toFixed(2)
            let formatted_rrp =
              "$" + rrp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            _variation.formattedPrice = formatted_rrp
            _allVariations[i] = _variation
            if (hash == _variation_id) {
              setActiveObjectID(_variation_id)
              setVariation(_variation)
              let galleryItems = _variation.Gallery
              let _gallery = []
              if (galleryItems !== null) {
                var j = 0
                for (; galleryItems[j]; ) {
                  let galleryItem = galleryItems[j].UndigitalGalleryImage
                  galleryItem.imagePosition = variation.imagePosition
                  _gallery.push(galleryItem)
                  j++
                }
                setGallery(_gallery)
              }
            }
          }
          i++
        }
      }
    }
  }, [variations])
  var brand_name = item.Brand ? item.Brand.UndigitalBrand.name : null
  var disclaimers = ""
  var disclaimerArr = []
  var disclaimerList = item.Disclaimer
  if (disclaimerList !== "undefined" && disclaimerList) {
    disclaimerList.forEach(function (item, index) {
      disclaimerArr.push(item.UndigitalTextAsset.content)
    })
    disclaimers = disclaimerArr.join(", ")
  }

  function executeOnClick(isExpanded) {}
  const updateVariation = variation => {
    let found = false
    for (const board of currentBoards) {
      const items = JSON.parse(board.Items)
      for (const key in items) {
        if (
          !found &&
          key.includes("Furniture") &&
          items[key].ID == item.objectID &&
          items[key].VariantID == variation.objectID
        ) {
          setIsSaved(true)
          found = true
        }
      }
    }
    if (!found) setIsSaved(false)
    setActiveObjectID(variation.objectID)
    if (typeof window !== "undefined") {
      window.history.replaceState(null, null, "?vid=" + variation.objectID)
    }
    setVariation(variation)
    
    let imagePosition = variation.imagePosition

    let galleryItems = variation.Gallery
    let _gallery = []
    if (galleryItems !== null) {
      var j = 0
      for (; galleryItems[j]; ) {
        let galleryItem = galleryItems[j].UndigitalGalleryImage
        galleryItem.imagePosition = variation.imagePosition
        _gallery.push(galleryItem)
        j++
      }
      setGallery(_gallery)
    }
  }

  const [showCreateBoard, setShowCreateBoard] = useState(false)

  const showAddBoard = () => {
    if (isLoggedIn()) {
      setShowCreateBoard(true)
    } else {
      if (typeof window !== "undefined") {
        window.location.href = "/login/"
      }
    }
  }

  if (item && item.status === "Active") {
    const recentPages = getRecentPages()
    if (vid) {
      if (
        variation &&
        typeof variation.status !== "undefined" &&
        variation.status == "Active"
      ) {
        item.variant = variation
        setRecentPages([
          { ...item, className: "Furniture" },
          ...recentPages.filter(function (rp) {
            return !(
              rp.className == "Furniture" &&
              rp.objectID == item.objectID &&
              typeof rp.variant !== "undefined" &&
              rp.variant.objectID == item.variant.objectID
            )
          }),
        ])
      }
    } else {
      setRecentPages([
        { ...item, className: "Furniture" },
        ...recentPages.filter(function (rp) {
          return !(
            rp.className == "Furniture" &&
            rp.objectID == item.objectID &&
            typeof rp.variant == "undefined"
          )
        }),
      ])
    }
  }

  const [customData, setCustomData] = useState(variation)
  const [loaded, setLoaded] = useState(false)
  const imgElement = useRef(null)

  useEffect(() => {
    setCustomData(variation)
  }, [variation])

  const createBoardPopup = useMemo(() => {
    return (
      <CreateBoardModal
        show={showCreateBoard}
        handleClose={() => {
          setShowCreateBoard(false)
        }}
        objectClass="Furniture"
        objectID={item.objectID}
        variantID={variation.objectID}
      />
    )
  }, [currentBoards, showCreateBoard, item, variation])
  
  return (
    <Layout hideHeader>
      {createBoardPopup}
      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/explore/">Explore</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/explore/furniture/">Furniture</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {item.name}
            </li>
          </ol>
        </nav>

        <div className="main-title-wrap">
          <div className="board-page-title flex-1">
            <div className="row align-items-center">
              <div className="col-md">
                <h1 className="product-name-title text-uppercase h2">
                  {item.name}
                </h1>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="button-link text-decoration-none ml-1"
          >
            <div
              className="d-flex flex-column align-items-center"
              onClick={() => showAddBoard()}
            >
              {isSaved ? (
                <svg
                  className="mb-1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  viewBox="0 0 35 35"
                >
                  <path
                    id="Path_878"
                    data-name="Path 878"
                    d="M-4.556-1.307c-5.906-10.042-18.85-3.3-16.35,8.076C-18.458,17.9-4.556,23.792-4.556,23.792S9.348,17.9,11.794,6.769C14.295-4.611,1.352-11.349-4.556-1.307Z"
                    transform="translate(21.982 6.975)"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="1.5"
                  />
                </svg>
              ) : (
                <svg
                  focusable="false"
                  width="30px"
                  height="30px"
                  className="mb-1"
                >
                  <use xlinkHref="/img/icons.svg#icon-heart"></use>
                </svg>
              )}
              {isSaved ? "LOVED" : "ADD"}
            </div>
          </button>
        </div>

        <div className="wrap-section row">
          <div className="col-md-8">
            <h3 className="info-title">
              {variation ? variation.name : item.name}
            </h3>
            {gallery.length > 0 ? (
                <ProductThumbnailsData gallery={gallery} />
            ):(
                <div
                    className={`landing-detail-image-wrap mt-3 mb-4 ${
                      variation.imagePosition
                    } ${!variation.productImagePath ? " no-image" : ""}`}
                  >
                    {variation.productImagePath && (
                      <a
                        href={variation.productImagePath}
                        className="d-block main-img pointer"
                        data-fancybox="gallery"
                        data-width={
                          loaded && imgElement.current.src.slice(-3) === "svg"
                            ? imgElement.current.naturalWidth * 9
                            : ""
                        }
                        data-height={
                          loaded && imgElement.current.src.slice(-3) === "svg"
                            ? imgElement.current.naturalHeight * 9
                            : ""
                        }
                      >
                        <img
                          src={variation.productImagePath}
                          alt={variation.name}
                          ref={imgElement}
                          onLoad={() => setLoaded(true)}
                        />
                      </a>
                    )}
                  </div>
            )}
            {_allVariations.length > 1 && (
              <div className="other-available-wrap mb-5">
                <div className="title-swiper">
                  <h3 className="main-title home-design-subtitle">
                    <span>Other options:</span>
                  </h3>
                  <div className="swiper-button-prev-unique"></div>
                  <div className="swiper-button-next-unique"></div>
                </div>
                <div className="other-available">
                  <Swiper navigation {...swiper_params}>
                    {_allVariations.map((variationItem, idx) => (
                      <SwiperSlide key={`variation${idx}`}>
                        <div
                          onClick={() => updateVariation(variationItem)}
                          className={`product-items clickable${
                            variationItem.objectID === activeObjectID
                              ? " active"
                              : ""
                          }`}
                        >
                          <div
                            className={`product-items-thumb ${
                              variationItem.objectID === activeObjectID
                                ? "active"
                                : ""
                            } ${variationItem.imagePosition}`}
                          >
                            <img
                              alt=""
                              src={variationItem.productImagePath}
                              alt=""
                            />
                          </div>
                          <p className="product-items-desc">
                            {variationItem.name}
                            {globalConfig.hidePricingGlobally == 0 && (
                              <>
                                {variationItem.name ? " | " : ""}
                                {variationItem.formattedPrice}
                              </>
                            )}
                          </p>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-4 information">
            <h3 className="mb-2 info-title">Information</h3>
            <div className="list-group list-group-flush mb-4">
              {item.about && (
                <div className="list-group-item px-0 about-wrap">
                  <ShowMoreText
                    more={<small className="more">Read more</small>}
                    less={<small className="less">Read less</small>}
                    className="content-wrap"
                    anchorClass="button-link d-block mt-1 text-right"
                    expanded={false}
                    width={0}
                  >
                    <span dangerouslySetInnerHTML={{ __html: item.about }} />
                  </ShowMoreText>
                </div>
              )}
              {globalConfig.hidePricingGlobally == 0 && (
                <div className="list-group-item px-0">
                  <div className="row align-items-center">
                    <div className="col">
                      <h5 className="mb-0">Price from</h5>
                    </div>
                    <div className="col-auto">
                      <p className="h4">{variation.formattedPrice}</p>
                    </div>
                  </div>
                </div>
              )}
              {brand_name && (
                <div className="list-group-item px-0">
                  <div className="row align-items-center">
                    <div className="col">
                      <h5 className="mb-0">Brand</h5>
                    </div>
                    <div className="col-auto">
                      <p className="font-weight-medium-light">{brand_name}</p>
                    </div>
                  </div>
                </div>
              )}
              {variation.ColourText && (
                <div className="list-group-item px-0">
                  <div className="row">
                    <div className="col">
                      <h5 className="mb-0">Colour</h5>
                    </div>
                    <div className="col-auto">
                      <p className="font-weight-medium-light">
                        {variation.ColourText}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {variation.StyleText && (
                <div className="list-group-item px-0">
                  <div className="row align-items-center">
                    <div className="col">
                      <h5 className="mb-0">Style</h5>
                    </div>
                    <div className="col-auto">
                      <p className="font-weight-medium-light">
                        {variation.StyleText}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <SlideToggle collapsed="false">
                {({ toggle, setCollapsibleElement, toggleState }) => (
                  <div className={`list-group-item px-0${
                    toggleState == "EXPANDED" ? " expanded" : ""
                  }`}>
                    <h5
                      className={`mb-0 info-toggle${
                        toggleState == "EXPANDED" ? " active" : ""
                      }`}
                      onClick={e => {
                        toggle(e)
                        e.preventDefault()
                      }}
                    >
                      Specs
                    </h5>
                    <div className="content-wrap" ref={setCollapsibleElement}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: variation.specs,
                        }}
                      />
                    </div>
                  </div>
                )}
              </SlideToggle>
              {item.additionalInfo && (
                <SlideToggle collapsed="false">
                  {({ toggle, setCollapsibleElement, toggleState }) => (
                    <div className={`list-group-item px-0${
                      toggleState == "EXPANDED" ? " expanded" : ""
                    }`}>
                      <h5
                        className={`mb-0 info-toggle${
                          toggleState == "EXPANDED" ? " active" : ""
                        }`}
                        onClick={e => {
                          toggle(e)
                          e.preventDefault()
                        }}
                      >
                        Additional Information
                      </h5>
                      <div
                        className="content-wrap"
                        ref={setCollapsibleElement}
                      >
                        <div
                        dangerouslySetInnerHTML={{
                          __html: item.additionalInfo,
                        }}
                      />
                      </div>
                    </div>
                  )}
                </SlideToggle>
              )}
            {item.warranty && (
                  <div className="list-group-item px-0">
                    <h5 className="mb-0">Warranty</h5>
                    <ShowMoreText
                      more={<small className="more">Read more</small>}
                      less={<small className="less">Read less</small>}
                      className="content-wrap"
                      anchorClass="button-link d-block mt-1 text-right"
                      expanded={false}
                      width={0}
                    >
                     <div
                        dangerouslySetInnerHTML={{ __html: item.warranty }}
                      />
                    </ShowMoreText>
                  </div>
                )}
              {disclaimers && (
                <SlideToggle collapsed="false">
                  {({ toggle, setCollapsibleElement, toggleState }) => (
                    <div className={`list-group-item px-0${
                      toggleState == "EXPANDED" ? " expanded" : ""
                    }`}>
                      <h5
                        className={`mb-0 info-toggle${
                          toggleState == "EXPANDED" ? " active" : ""
                        }`}
                        onClick={e => {
                          toggle(e)
                          e.preventDefault()
                        }}
                      >
                        DISCLAIMER
                      </h5>
                      <div
                        className="content-wrap"
                        ref={setCollapsibleElement}
                      >
                        <span
                          dangerouslySetInnerHTML={{ __html: disclaimers }}
                        />
                      </div>
                    </div>
                  )}
                </SlideToggle>
              )}
            </div>

            <div className="row justify-content-center mt-30">
              <div className="col-xl-8 col-md-12 col-sm-6 col-8">
                <a
                  href="/contact-us/"
                  className="btn btn-black btn-block btn-lg px-2 my-1"
                >
                  Enquire now
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="pb-4">
          <FurnitureRelatedItems id={item.objectID} />
        </div>
        <RecentViews />
      </div>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    silverStripeDataObject(
      className: { eq: "Undigital__Objects__GlobalConfig" }
    ) {
      UndigitalGlobalConfig {
        hidePricingGlobally
      }
    }
    allSilverStripeDataObject(
      filter: {
        UndigitalFurniture: {
          urlSegment: { eq: $slug }
          status: { in: ["Active", "Draft"] }
        }
      }
    ) {
      nodes {
        UndigitalFurniture {
          status
          name
          urlSegment
          objectID
          about
          additionalInfo
          warranty
          Brand {
            UndigitalBrand {
              name
            }
          }
          Disclaimer {
            UndigitalTextAsset {
              content
            }
          }
          FeaturedVariation {
            UndigitalFurnitureVariation {
              name
              objectID
              status
              rrp
              productImagePath
              imagePosition
              specs
              Style {
                UndigitalProductStyle {
                  name
                }
              }
              Colour {
                UndigitalColour {
                  name
                }
              }
              Gallery {
                UndigitalGalleryImage {
                  imagePath
                  caption
                }
              }
            }
          }
          Variations {
            UndigitalFurnitureVariation {
              name
              objectID
              status
              specs
              rrp
              productImagePath
              imagePosition
              Colour {
                UndigitalColour {
                  name
                }
              }
              Style {
                UndigitalProductStyle {
                  name
                }
              }
              Gallery {
                UndigitalGalleryImage {
                  imagePath
                  caption
                }
              }
            }
          }
        }
      }
    }
  }
`

const mapStateToProps = state => ({
  currentBoards: state.board.all,
})

export default connect(mapStateToProps, null)(FurnitureDetail)
